/* Ionic Modal Component on desktop */
ion-split-pane + .sponsor-modal-desktop {
  padding-left: 360px;
}
@media (min-width: 767px) {
  .sponsor-modal-desktop {
    --height: 90%;
    --width: 90%;
  }
}

.sponsor-modal {

  &--presentation-panel {
    padding: 0;
    text-align: center;

    &.no-visual {
      .sponsor-modal--description p:first-child {
        margin-right: 35px;
      }
    }

    h2 {
      padding: 0 calc(var(--esch-padding-of-modals) * 2) 0;
    }
    
    li {
      text-align: start;
    }
  }

  &--favorite-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 46px;
    height: 46px;
    --padding-start: 0;
    --padding-end: 0;
    &.ion-color-light {
      --riple-color: transparent;
      --ion-color-base: var(--esch-color-gray-of-parts) !important;
      ion-icon {
        font-size: 18px;
        --ion-color-base: var(--esch-color-gray-of-primary-texts) !important;
      }
    }
    &.no-visual {
      float: right;
      position: relative;
    }
  }

  &--logo,
  &--visuals {
    max-height: 500px;

    &::part(image) {
      max-height: 500px;
    }
  }
  &--logo {
    margin: 55px auto;
    max-width: 80%;
    object-fit: contain;
  }
  &--visuals {
    height: 500px;
    object-fit: cover;
  }

  &--description {
    padding: 0 calc(var(--esch-padding-of-modals) * 2) calc(var(--esch-padding-of-modals) * 2);
  }

  &--data,
  ion-grid {
    padding: 0;
    text-align: start;
    margin-top: calc(var(--esch-padding-of-modals) * 2);
    --ion-grid-column-padding: calc(var(--esch-padding-of-modals) * 2);
    --ion-grid-column-padding-xl: calc(var(--esch-padding-of-modals) * 2);
    ion-col {
      border-top: 1px solid var(--esch-color-gray-of-parts-borders);
      border-right: 1px solid var(--esch-color-gray-of-parts-borders);
      &:nth-child(2) {
        border-right: none;
      }
    }
    h3 {
      margin-top: 0;
    }
    p {
      a {
        font-weight: 600;
        text-decoration: none;
        color: var( --esch-color-blue-of-any-thing-else);
      }
    }
  }
  &--data {
    margin-top: 0;
    padding: calc(var(--esch-padding-of-modals) * 2);
    border-top: 1px solid var(--esch-color-gray-of-parts-borders);
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  iframe {
    max-width: 100%;
    max-height: 500px;
  }
}
