.card-grid-item {
  position: relative;
  display: inline-block;
  margin: 6px;
  width: calc(100% - 12px);
  height: 460px;
  min-height: 460px;
  max-height: 460px;

  &--card {
    padding: 0;
    --padding-top: 0;
    --background: var(--esch-color-white-of-any-thing-else);
    ion-card-header {
      padding-top: 0;
      padding-bottom: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    ion-card-content {
      padding-top: 16px;
      padding-bottom: 28px;
    }
  }

  &--favorite-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 46px;
    height: 46px;
    --padding-end: 0;
    --padding-start: 0;
    &.ion-color-light {
      --riple-color: transparent;
      --ion-color-base: var(--esch-color-gray-of-parts) !important;
      ion-icon {
        font-size: 18px;
        --ion-color-base: var(--esch-color-gray-of-primary-texts) !important;
      }
    }
  }

  &--visuals {
    width: 100%;
    height: 200px;
    max-height: 200px;
    object-fit: cover;

    .company-card & {
      width: 80%;
      margin: 12px auto;
      object-fit: contain;
    }
  }

  &--title {
    text-align: center;
  }

  &--description {
    margin: 12px 0 24px;
    text-align: center;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
  }

  &--button-container {
    margin: 12px 0 0;
    text-align: center;
    ion-button {
      width: auto;
      height: var(--esch-buttons-height);
      min-height: var(--esch-buttons-height);
      --padding-start: calc(var(--esch-padding-of-content) / 3);
      --padding-end: calc(var(--esch-padding-of-content) / 3);
    }
  }
}

ion-split-pane .card-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px;

  &:nth-child(3n+1) {
    margin-left: 0;
  }
}
