.resource-tile {
  width: 100%;
  height: 220px;
  box-shadow:
    0px 0px 3px -1px rgba(0, 0, 0, 0.3),
    0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  --padding-start: 0;
  --padding-end: 0;
  transition: all 0.1s ease-in;

  &--container {
    display: inline-block;
    margin: 6px;
    width: calc(100% / 2 - 12px);
    position: relative;
  }

  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  &--icon {
    display: block;
    font-size: 46px;
  }

  &--delete {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 36px;
    height: 36px;
    --padding-start: 0;
    --padding-end: 0;
    &.ion-color-medium {
      --ion-color-base: var(--esch-color-gray-of-tables-borders) !important;
      --ion-color-base-rgb: transparent !important;
      --ion-color-shade: var(--esch-color-gray-of-tables-borders) !important;
      --ion-color-tint: var(--esch-color-gray-of-tables-borders) !important;
    }
  }

  &--title {
    display: block;
    margin: 16px auto;
    font: 500 16px/20px Barlow, sans-serif;
    text-transform: none;
    width: 96%;
    white-space: normal;
    line-height: 1.6;
  }

  &--size {
    margin: 0;
    font: 400 14px/16px Barlow, sans-serif;
    color: var(--ion-color-medium, #666);
  }

  &--hidden-input {
    display: none;
  }

  &-add {
    color: var(--esch-color-gray-of-selects-text);
    box-shadow: none;
    --background-hover: transparent;

    .resource-tile--icon {
      font-size: 32px;
    }
  }
}

ion-split-pane .resource-tile {
  height: 260px;

  &--container {
    width: calc(100% / 3 - 20px);
    margin: 10px;
    --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
    --background-hover: var(--esch-color-blue-of-parts);
    --background-activated-opacity: 0;

    &:hover .resource-tile--delete {
      display: block;
    }

    ion-button {
      &.resource-tile-add {
        border: 2px dotted var(--esch-color-gray-of-tables-borders);
      }
      &:not(.resource-tile-add) {
        background: white;
      }
      &.resource-tile--delete {
        background: transparent;
      }
      a {
        padding: 10px;
      }
    }
  }

  &--delete {
    display: none;
  }

  &:hover {
    box-shadow:
      0px 0px 3px -1px rgba(0, 0, 0, 0.3),
      0px 8px 8px 4px rgba(0, 0, 0, 0.1);
    transform: translate(0px, -7px);
  }
  &-add {
    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
}
