/* Desktop & Mobile  display */

.main-content {
  ion-header {
    ion-toolbar {
      --min-height: var(--esch-buttons-of-the-topbar-height);
      --padding-end: calc(var(--esch-padding-of-topbar) - var(--esch-buttons-of-the-topbar-right-margin));
      ion-title {
        z-index: 1;
        font-weight: 600;
        user-select: none;
        font-size: var(--esch-fontsize-of-the-topbar-title);
        padding-inline-start: var(--esch-padding-of-topbar);
        --color: var(--esch-color-gray-of-primary-texts);
        &:nth-child(2) {
          padding-inline-start: 0;
        }
      }
      ion-buttons {
        &[slot="start"]{
          margin-inline-start: calc(var(--esch-padding-of-topbar) / 4);
          margin-inline-end: calc(var(--esch-padding-of-topbar) / 4);
          --color: var(--esch-color-gray-of-primary-texts);
          --ion-toolbar-color: var(--esch-color-gray-of-primary-texts);
        }
        &[slot="primary"]{
          height: var(--esch-buttons-of-the-topbar-height);
          margin-inline-end: 0;
          --background: transparent;
          ion-button {
            height: inherit;
            margin-right: var(--esch-buttons-of-the-topbar-right-margin);
            margin-inline-end: var(--esch-buttons-of-the-topbar-right-margin);
            margin-inline-start: 0;
            text-transform: none;
            letter-spacing: var(--esch-buttons-letter-spacing);
            position: relative;
            font-size: var(--esch-fontsize-of-the-topbar-buttons);
            --padding-end: 0;
            --padding-start: 0;
            --border-radius: 0;
            --background: transparent;
            --ripple-color: transparent;
            --background-activated-opacity: .2;
            --background-activated: var(--esch-color-gray-of-parts);
            --background-focused: var(--esch-color-gray-of-parts);
            --background-focused-opacity: .2;
            --background-hover: var(--esch-color-gray-of-parts);
            --background-hover-opacity: .2;
            --color: var(--esch-color-gray-of-secondary-texts);
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: transparent;
            }
            &.selected {
              opacity: 1;
              --color: var(--esch-color-red-of-any-thing-else);
              &::after {
                background-color: var(--esch-color-red-of-any-thing-else);
              }
            }
          }
        }
      }
      &.secondary-toolbar {
        --min-height: calc(var(--esch-buttons-of-the-topbar-height) - 10px);
        --background: var(--esch-color-gray-of-secondary-toolbars);
        ion-title {
          font-size: var(--esch-fontsize-of-the-topbar-secondary-title);
        }
        ion-buttons {
          height: calc(var(--esch-buttons-of-the-topbar-height) - 10px);
          &[slot="primary"] {
            height: 100%;
          }
          ion-button{
            border-radius: 3px;
            margin-right: calc(var(--esch-buttons-of-the-topbar-right-margin) - 15px);
            --padding-end: 12px;
            --padding-start: 12px;
            --padding-top: 10px;
            --padding-bottom: 10px;
            &::after {
              display: none;
            }
            &.selected {
              --color: var(--esch-color-white-of-any-thing-else);
              background-color: var(--esch-color-gray-of-any-thing-else);
            }
            ion-badge {
              margin-left: 6px;
              margin-top: 2px;
            }
          }
        }
      }
    }
    &::after {
      bottom: -1px;
      height: 1px;
      background-image: none;
      background-color: var(--esch-color-gray-of-parts-borders);
    }
  }
  ion-content {
    --padding-end: var(--esch-padding-of-content);
    --background: var(--esch-color-gray-of-parts);
    --padding-top: var(--esch-padding-of-content);
    --padding-start: var(--esch-padding-of-content);
    --padding-bottom: var(--esch-padding-of-content);
    &.overscroll {
      --padding-start: 0;
      --padding-bottom: 0;
    }
    &.sponsors-grid {
      .card-grid--title {
        max-width: 100%;
      }
      .card-grid {
        flex-wrap: wrap;
        max-width: 100%;
        .card-grid-item {
          width: calc((100% / 3) - 20px);
        }
      }
    }
    ion-list,
    .project--section {
      padding: 0;
      margin: auto;
      border-radius: 0;
      max-width: var(--esch-maxwidth-of-parts);
      box-shadow: var(--esch-boxshadow-of-parts);
      border: 1px solid var(--esch-color-gray-of-parts-borders);
      --color: var(--esch-color-gray-of-any-thing-else);
      &-title {
        max-width: var(--esch-maxwidth-of-parts);
        margin: 32px auto 14px auto;
        font-weight: 500;
      }
      &-item {
        --border-color: transparent;
        --highlight-background: transparent;
      }
    }
    ion-list + .list-title {
      padding-top: var(--esch-padding-of-content);
    }
  }
}

/* Desktop display */

.main-content.desktop {

  &::shadow .menu-inner {
    z-index: 2;
  }

  .side-menu {
    width: var(--esch-width-of-the-navigation-left-panel);
    --background: var(--esch-color-red-of-parts);

    &--contents {
      padding: 0;
      position: relative;
      overflow: auto;
      z-index: 2;
      height: 100%;

      &::before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: url(/assets/background.jpg) no-repeat center center;
        background-size: cover;
        opacity: var(--esch-opacity-of-background-image-over-red-parts);
      }
    }

    &--logo {
      z-index: 2;
      position: relative;
      width: var(--esch-width-of-the-logo-if-small);
      margin: var(--esch-padding-of-the-navigation-left-panel-logo) auto calc(var(--esch-padding-of-the-navigation-left-panel-logo) + 20px) auto;
    }

    &--title {
      margin: var(--esch-padding-of-the-navigation-left-panel-logo) 0 0 0;
      padding: 0 var(--esch-padding-of-the-navigation-left-panel-items);
      font-weight: 700;
      font-size: 24px;
      color: var(--ion-color-light, #fff);
    }

    &--list {
      margin: 0;
      padding: 0;
      z-index: 2;
      position: relative;
      list-style-type: none;
      ul {
        margin: 0;
        padding: 0;
      }
      &:nth-child(2){
        margin-bottom: 0;
      }
      &.resources{
        padding: var(--esch-padding-of-the-navigation-left-panel-items) 0;
        margin-top: var(--esch-padding-of-the-navigation-left-panel-items);
        margin-bottom: var(--esch-padding-of-the-navigation-left-panel-items);
        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--esch-color-white-of-parts-borders);
          opacity: .7;
        }
        &::after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--esch-color-white-of-parts-borders);
          opacity: .7;
        }
      }
    }

    &--item {
      display: block;
      padding: 14px 0 14px 0;
      margin: 0 var(--esch-padding-of-the-navigation-left-panel-logo);
      color: var(--esch-color-pink-of-over-red-secondary-texts);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--esch-color-white-of-over-red-primary-texts);
      }

      &::before {
        display: inline-block;
        margin-right: 10px;
        width: 20px;
        color: inherit;
        font-weight: 700;
        font-family: "Font Awesome 5 Pro";
        text-align: center;
        content: "\f10d";
      }

      &.project::before {
        content: '\f1b3';
      }
      &.projects-list::before {
        content: '\f03a';
      }
      &.project-create::before {
        content: '\f055';
      }
      &.resources::before {
        content: '\f15c';
      }
      &.account::before {
        content: '\f0c0';
      }
      &.settings::before {
        content: '\f013';
      }
      &.log-out::before {
        content: '\f08b';
      }

      &--selected {
        color: var(--esch-color-white-of-over-red-primary-texts, #fff);

        &::before {
          font-weight: 900;
        }
      }

    }

    &--disguise {
      position: relative;
      z-index: 2;
      color: white;
      padding: 24px;
      text-align: center;
    }

    &--disguise-name {
      margin-bottom: 12px;
    }

  }

}
