.esch-auth-title {
  color: var(--esch-color-white-of-over-red-primary-texts);
  font-size: 32px;
  line-height: 1.1;
}

.esch-auth-subtitle {
  color: var(--esch-color-white-of-over-red-secondary-texts);
  font-size: 20px;
  font-weight: 300;
}
