.auth-content {
  // --background: var(--esch-color-red-of-parts);
  border-bottom: 25px solid #ffffff;
  // --background: var(--esch-color-red-background-cover);
  --background:
    linear-gradient(to bottom, var(--esch-color-red-background-cover), var(--esch-color-red-background-cover)),
    url(/assets/background.jpg) no-repeat center center / cover;

  &--center {
    position: relative;
    z-index: 3;
  }

  &::part(scroll) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 25px solid #ffffff;
    border-bottom: none;
    box-sizing: border-box;
    z-index: 4;
    pointer-events: none;
  }

  .esch {
    &.auth-form-wrap {
      padding: 0;
      border: none;
      width: inherit;
      max-width: inherit;
      background-color: var(--esch-color-white-of-parts);
    }
    &.auth-form-content-switch {
      color: var(--esch-color-white-of-over-red-primary-texts);
      --ion-color-dark: var(--esch-color-white-of-over-red-primary-texts);
    }
    .auth-form-content{
      padding: var(--esch-padding-of-forms-containers);
      --ion-item-background: var(--esch-color-white-of-any-thing-else);
      ion-item{
        margin-top: var(--esch-padding-of-forms-submit-containers);
        --border-width: 0;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        --background-focused: transparent;
        --highlight-color-focused: transparent;
        --background-focused-opacity: transparent;
        &:nth-child(1){
          margin-top: 0;
        }
        ion-label[position="stacked"]{
          font-size: var(--esch-fontsize-of-any-thing-else);
          max-width: 100%;
          width: 100%;
          transform: none;
          margin-bottom: 15px;
          --ion-color-primary: var(--esch-color-gray-of-any-thing-else);
        }
        ion-input{
          background: var(--esch-color-gray-of-inputs);
          --padding-start: 20px;
          --padding-end: 20px;
        }
      }
    }
    .button-container{
      border-top: 1px solid var(--esch-color-blue-of-parts-borders);
      background-color: var(--esch-color-blue-of-parts);
      padding: var(--esch-padding-of-forms-submit-containers) 0;
      ion-button{
        &.ion-sign-in-button {
          width: var(--esch-buttons-width);
        }
        &.ion-sign-up-sponsor-button {
          width: 216px;
        }
        &.ion-sign-up-leader-button {
          min-width: var(--esch-buttons-width);
        }
        &.ion-change-password-button {
          min-width: var(--esch-buttons-width);
        }
        &.ion-reset-password-button {
          min-width: var(--esch-buttons-width);
        }
      }
    }
  }

}

.auth-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2 0 auto;
  width: 93%;
  max-width: var(--esch-maxwidth-of-forms-containers);
  padding: 18px 0;
  text-align: center;
  --ion-item-background: var(--esch-color-white-of-parts);

  &-choose {
    max-width: calc(var(--esch-maxwidth-of-forms-containers) * 2);
  }

  &-wrap {
    display: inline-block;
    vertical-align: top;
    width: 344px;
    margin: 32px 0 0 0;
    padding: 20px;
    --ion-item-background: var(--esch-color-white-of-any-thing-else);
    background-color: var(--ion-item-background);
    h3, p {
      --ion-text-color: var(--esch-color-gray-of-any-thing-else);
      --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
    }

    & + .auth-form-wrap {
      margin-left: 18px;
    }
  }

  &--subtitle {
    margin: 0 0 12px;
  }

  &--description {
    margin: 12px 0;
    min-height: 64px;
  }

  &--aligner {
    flex: 1;
  }
}

.login--app-title {
  font-size: 32px;
  text-align: center;
}

.auth-form-content,
.sign-in-button,
.auth-error {
  margin: 0 auto !important;
  max-width: var(--esch-maxwidth-of-forms-containers);
}

.auth-form-content-switch {
  display: flex;
  max-width: var(--esch-maxwidth-of-forms-containers);
  margin: 20px auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ion-button{
    margin: 0 6px;
  }
}

.auth-error {
  padding: 0 var(--esch-padding-of-forms-containers) var(--esch-padding-of-forms-containers) var(--esch-padding-of-forms-containers);
  color: var(--ion-color-danger, #f00);
}

ion-card {
  max-width: var(--esch-maxwidth-of-forms-containers);
  margin: auto;
  padding: 50px;
}

ion-card-subtitle {
  max-width: var(--esch-maxwidth-of-forms-containers);
  margin: auto;
  padding: 20px;
}

.e22-flex-center {
  display: flex;
  justify-content: center;
}
