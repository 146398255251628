/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-default-font: 'Barlow', sans-serif;
  --ion-font-family: 'Barlow', sans-serif;

  /** primary **/
  --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
  --ion-color-primary-rgb: 0, 161, 161;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--esch-color-blue-button-hover);
  --ion-color-primary-tint: #01c8c8;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 144, 144, 144;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /**********************************************************/
  /********* Reborn for ESCH 2022 variables (light) *********/
  /**********************************************************/

  --ion-backdrop-color: rgb(0, 0, 0);
  --ion-backdrop-opacity: 0.50;

  --esch-color-gray-of-primary-texts: #1e1b1b;
  --esch-color-gray-of-secondary-texts: #6f6f6f;
  --esch-color-gray-of-parts: #f8f8f8;
  --esch-color-gray-of-parts-borders: #eeeeee;
  --esch-color-gray-of-secondary-toolbars: #eaeaea;
  --esch-color-gray-of-tables-borders: #aaaaaa;
  --esch-color-gray-of-tables-thead: #e3e3e3;
  --esch-color-gray-of-inputs: #f8f8f8;
  --esch-color-gray-of-inputs-text: #282828;
  --esch-color-gray-of-inputs-disabled: #eeeeee;
  --esch-color-gray-of-inputs-disabled-text: #aaaaaa;
  --esch-color-gray-of-selects: #f8f8f8;
  --esch-color-gray-of-selects-text: #282828;
  --esch-color-gray-of-table-th: #2f2a2a;
  --esch-color-gray-of-any-thing-else: #282828;

  --esch-color-white-of-primary-texts: white;
  --esch-color-white-of-secondary-texts: rgba(255, 255, 255, 0.8);
  --esch-color-white-of-over-red-primary-texts: white;
  --esch-color-white-of-over-red-secondary-texts: rgba(255, 255, 255, 0.8);
  --esch-color-white-of-parts: white;
  --esch-color-white-of-parts-borders: rgba(255, 255, 255, 0.4);
  --esch-color-white-of-any-thing-else: white;

  --esch-color-red-of-parts: #c50003;
  --esch-color-red-background-cover: rgba(197, 0, 3, 0.94);
  --esch-color-red-of-any-thing-else: #ed1d24;
  --esch-color-red-button-shadow: 4px 10px 10px rgba(237, 29, 36, 0.149);
  --esch-color-red-button-hover: #db191f;

  --esch-color-blue-of-parts: #f2fbfb;
  --esch-color-blue-of-parts-borders: #dcf5f5;
  --esch-color-blue-of-any-thing-else: #00a1a1;
  --esch-color-blue-shadow-of-any-thing-else: 4px 10px 10px rgba(1,181,175,0.15);
  --esch-color-blue-button-hover: #007a7a;
  --esch-color-blue-button-shadow: 4px 10px 10px rgba(1,181,175,0.15);

  --esch-color-button-hover: #000;

  --esch-color-pink-of-over-red-secondary-texts: #e8a6a6;

  --esch-color-of-disabled-buttons-text: #8a8a8a;
  --esch-color-of-disabled-buttons-background: #eeeeee;
  --esch-value-of-disabled-buttons-boxshadow: none;

  --esch-boxshadow-of-parts: 0 0 6px 0 rgba(152,152,152,0.04);
  --esch-distance-between-a-checkbox-and-its-label: 5px;
  --esch-fontsize-of-the-topbar-title: 21px;
  --esch-fontsize-of-the-topbar-secondary-title: 16px;
  --esch-fontsize-of-the-topbar-buttons: 15px;
  --esch-fontsize-of-any-thing-else: 15px;
  --esch-fontweight-of-buttons: 600;
  --esch-fontweight-of-any-thing-else: 400;
  --esch-height-of-harmonized-input-field: 48px;
  --esch-height-of-bottom-action-bars: 96px;
  --esch-width-of-the-logo-if-big: 104px;
  --esch-width-of-the-logo-if-small: 77px;
  --esch-width-of-the-navigation-left-panel: 350px;
  --esch-margin-between-two-forms-submit-buttons: 20px;
  --esch-maxheight-of-forms-submit-containers: 97px;
  --esch-maxwidth-of-forms-containers: 450px;
  --esch-maxwidth-of-parts: 800px;
  --esch-opacity-of-background-image-over-red-parts: .04;
  --esch-padding-of-forms-containers: 40px;
  --esch-padding-of-forms-submit-containers: 20px;
  --esch-padding-of-forms-submit-buttons: 40px;
  --esch-padding-of-the-navigation-left-panel-logo: 60px;
  --esch-padding-of-the-navigation-left-panel-items: 20px;
  --esch-padding-of-topbar: 60px;
  --esch-padding-of-content: 60px;
  --esch-padding-of-content-on-mobile: 10px;
  --esch-padding-of-inputs: 15px;
  --esch-padding-of-modals: 15px;
  --esch-padding-top-of-content-parts: 30px;
  --esch-padding-bottom-of-content-parts: 30px;
  --esch-padding-start-of-content-parts: 40px;
  --esch-padding-end-of-content-parts: 40px;
  --esch-buttons-width: 190px;
  --esch-buttons-height: 56px;
  --esch-buttons-letter-spacing: .2px;
  --esch-buttons-of-the-topbar-height: 80px;
  --esch-buttons-of-the-topbar-right-margin: 30px;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /*********************************************************/
    /********* Reborn for ESCH 2022 variables (dark) *********/
    /*********************************************************/

    --ion-backdrop-color: rgb(0, 0, 0);
    --ion-backdrop-opacity: 0.50;

    --esch-color-gray-of-primary-texts: white;
    --esch-color-gray-of-secondary-texts: rgba(255, 255, 255, 0.7);
    --esch-color-gray-of-parts: #171616;
    --esch-color-gray-of-parts-borders: #383a3e;
    --esch-color-gray-of-secondary-toolbars: #3d2526;
    --esch-color-gray-of-tables-borders: #86888f;
    --esch-color-gray-of-tables-thead: #ddd8d7;
    --esch-color-gray-of-inputs: #2f2a2a;
    --esch-color-gray-of-inputs-text: #acacac;
    --esch-color-gray-of-inputs-disabled: #383a3e;
    --esch-color-gray-of-inputs-disabled-text: #86888f;
    --esch-color-gray-of-selects: #2f2a2a;
    --esch-color-gray-of-selects-text: #acacac;
    --esch-color-gray-of-table-th: #2f2a2a;
    --esch-color-gray-of-any-thing-else: white;

    --esch-color-white-of-primary-texts: black;
    --esch-color-white-of-secondary-texts: rgba(0, 0, 0, 0.8);
    --esch-color-white-of-over-red-primary-texts: white;
    --esch-color-white-of-over-red-secondary-texts: rgba(255, 255, 255, 0.8);
    --esch-color-white-of-parts: #330300;
    --esch-color-white-of-parts-borders: rgba(255, 255, 255, 0.4);
    --esch-color-white-of-any-thing-else: #3d2626;

    --esch-color-red-of-parts: #230a0a;
    --esch-color-red-background-cover: rgba(35, 10, 10, 0.94);
    --esch-color-red-of-any-thing-else: #a12121;
    --esch-color-red-button-shadow: 4px 10px 10px rgba(237, 29, 36, 0.149);
    --esch-color-red-button-hover: #db191f;

    --esch-color-blue-of-parts: #210f0e;
    --esch-color-blue-of-parts-borders: #583835;
    --esch-color-blue-of-any-thing-else: #007171;
    --esch-color-blue-shadow-of-any-thing-else: 4px 10px 10px rgba(125, 0, 5, 0.15);

    --esch-color-pink-of-over-red-secondary-texts: #e8a6a6;

    --esch-color-of-disabled-buttons-text: #8a8a8a;
    --esch-color-of-disabled-buttons-background: #1b0200;
    --esch-value-of-disabled-buttons-boxshadow: none;

    --esch-boxshadow-of-parts: 0 0 6px 0 rgba(152,152,152,0.04);
    --esch-distance-between-a-checkbox-and-its-label: 5px;
    --esch-fontsize-of-the-topbar-title: 21px;
    --esch-fontsize-of-the-topbar-secondary-title: 16px;
    --esch-fontsize-of-the-topbar-buttons: 15px;
    --esch-fontsize-of-any-thing-else: 15px;
    --esch-fontweight-of-buttons: 600;
    --esch-fontweight-of-any-thing-else: 400;
    --esch-height-of-harmonized-input-field: 48px;
    --esch-height-of-bottom-action-bars: 96px;
    --esch-width-of-the-logo-if-big: 104px;
    --esch-width-of-the-logo-if-small: 77px;
    --esch-width-of-the-navigation-left-panel: 350px;
    --esch-margin-between-two-forms-submit-buttons: 20px;
    --esch-maxheight-of-forms-submit-containers: 97px;
    --esch-maxwidth-of-forms-containers: 450px;
    --esch-maxwidth-of-parts: 800px;
    --esch-opacity-of-background-image-over-red-parts: .04;
    --esch-padding-of-forms-containers: 40px;
    --esch-padding-of-forms-submit-containers: 20px;
    --esch-padding-of-forms-submit-buttons: 40px;
    --esch-padding-of-the-navigation-left-panel-logo: 60px;
    --esch-padding-of-the-navigation-left-panel-items: 20px;
    --esch-padding-of-topbar: 60px;
    --esch-padding-of-content: 60px;
    --esch-padding-of-content-on-mobile: 10px;
    --esch-padding-of-inputs: 15px;
    --esch-padding-of-modals: 15px;
    --esch-padding-top-of-content-parts: 30px;
    --esch-padding-bottom-of-content-parts: 30px;
    --esch-padding-start-of-content-parts: 40px;
    --esch-padding-end-of-content-parts: 40px;
    --esch-buttons-width: 190px;
    --esch-buttons-height: 56px;
    --esch-buttons-letter-spacing: .2px;
    --esch-buttons-of-the-topbar-height: 80px;
    --esch-buttons-of-the-topbar-right-margin: 30px;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }
}
