.draft-panel {
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: var(--esch-maxwidth-of-parts, 800px);
  margin: 30px auto;
  padding: 16px 40px;

  background: var(--esch-color-blue-of-parts);
  border: 1px solid var(--esch-color-blue-of-parts-borders);

  @media (max-width: 640px) {
    flex-direction: column;
    margin: 30px 12px;
  }

  &--message {
    flex: 1 0;
    padding: 16px 32px 16px 0;

    h2 {
      margin: 8px 0;
      font-size: 18px;
    }
    p {
      margin: 8px 0;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
