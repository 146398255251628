.tabs-inner .top-navigation .only-desktop,
ion-split-pane .top-navigation .only-mobile {
  display: none;
}

ion-buttons.top-navigation {
  &--badge {
    font-size: 10px;
    margin-left: -6px;
    transform: translate(0px, -10px);
  }
}
