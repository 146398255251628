.auth-message {
  color: var(--esch-color-white-of-over-red-primary-texts);
  max-width: var(--esch-maxwidth-of-forms-containers);
  padding: 100px 18px 140px;
  text-align: center;
  font-family: var(--ion-font-family);

  &--type {
    font-size: 64px;
  }
  &--title {
    font-weight: 500;
    font-size: 32px;
    margin-block-end: 0;
    margin-bottom: 0;
  }
  &--description {
    font-size: 20px;
    color: var(--esch-color-white-of-over-red-secondary-texts);
    margin-top: 10px;
    margin-block-start: 10px;
    margin-block-end: calc(var(--esch-padding-of-forms-containers) + 4px);
    margin-bottom: calc(var(--esch-padding-of-forms-containers) + 4px);
  }
  p:last-child{
    margin-block-start: 0;
    margin-block-end: 0;
  }
}
