ion-split-pane  .list-title.list-title--wide {
  z-index: 3;
  position: sticky;
  top: calc(var(--esch-padding-of-content) * -1 + 6px);
  margin: 0 0 0 var(--esch-padding-of-content);
}

.table-container {
  padding: 0 var(--esch-padding-of-content) 0 0;

  &:before {
    left: 0;
    z-index: 2;
    width: 100%;
    content: '';
    position: fixed;
    right: 0;
    height: var(--esch-padding-of-content);
    top: calc(var(--esch-buttons-of-the-topbar-height) + 1px);
    background: var(--esch-color-gray-of-parts);
  }
  .projects-table {
    z-index: 1;
    width: 100%;
    border-radius: 0;
    border-spacing: 0;
    position: relative;
    border-collapse: collapse;
    margin: 14px calc(var(--esch-padding-of-content) * -1) var(--esch-padding-of-content) var(--esch-padding-of-content);
    box-shadow: var(--esch-boxshadow-of-parts);
    background-color: var(--esch-color-white-of-any-thing-else);
    --color: var(--esch-color-gray-of-any-thing-else);

    tbody tr:hover {
      background-color: var(--esch-color-gray-of-parts, #f8f8f8);
    }

    th {
      top: 0;
      z-index: 2;
      font-size: 15px;
      font-weight: 700;
      white-space: pre;
      position: sticky;
      text-align: left;
      color: var(--esch-color-gray-of-table-th);
      background: var(--esch-color-white-of-over-red-primary-texts);

      button {
        padding: 0;
        font-weight: inherit;
        font-size: inherit;
        background: transparent;
        text-decoration: underline;
        outline: none;
      }
    }
    td {
      font-size: 14px;
      line-height: 1.7;
    }
    th,
    td {
      margin: 0;
      padding: 20px 10px;
      border-bottom: 1px solid var(--esch-color-gray-of-parts-borders);
      vertical-align: top;

      &:first-child {
        text-align: right;
      }

      h2 {
        margin: 1px 0;
        font-size: 18px;
        color: var(--esch-color-blue-of-any-thing-else);

        ion-icon {
          font-size: 14px;
          margin: 0 4px 0 -16px;
          width: 12px;
        }
      }
      p {
        margin: 4px 0 0 0;
      }

      a {
        font-weight: 500;
        text-decoration: none;
        color: var(--esch-color-blue-of-any-thing-else);
      }
      ul {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 20px;
      }
    }

    &--link {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--esch-color-blue-button-hover, #007a7a);
      }
    }
    &--date-cell {
      white-space: nowrap;
      text-align: right;
    }
    &--action {
      width: 9.5rem;
    }
    &--sponsoring-cell {
      text-align: center;
    }
  }
}

.grid-container {
  margin: 0 var(--esch-padding-of-content) var(--esch-padding-of-content) var(--esch-padding-of-content);
}


/* Terms colors */
.terms {
  margin: 0 0 0.6rem 0;
}
.term {
  margin-right: 2px;
  padding: 2px;
  border-radius: 3px;
  white-space: nowrap;
  color: #000;
  background-color: #f4f4f4;

  &-filmLit {
    color: #716911;
    background-color: #f3e441;
  }
  &-music {
    color: #e62c2c;
    background-color: #ffbaba;
  }
  &-autre {
    color: #217111;
    background-color: #98fe83;
  }
  &-artDesign {
    color: #163670;
    background-color: #83affe;
  }
}
