.card-grid {
  margin: 0 6px;
  display: flex;

  &--title {
    font-family: Barlow, sans-serif;
    font-size: 18px;
    font-weight: 600;
  }
}

ion-split-pane .card-grid {
  margin: 0 auto;
  max-width: var(--esch-maxwidth-of-parts);

  &--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px auto 4px;
    max-width: var(--esch-maxwidth-of-parts);
    height: 41px;

    &:first-child {
      margin-top: 0;
    }
  }
}
