/* Mobile display (no media query related) */

.tabs-inner {
  ion-header {
    ion-toolbar {
      padding-top: 0 !important;
      --background: var(--esch-color-red-of-parts);
      --color: var(--esch-color-white-of-over-red-primary-texts);
      ion-title {
        padding-bottom: 0px;
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: auto;
        font-size: 17px;
      }
      ion-buttons {
        ion-button {
          font-size: 12px;
          --ripple-color: transparent;
          --color: var(--esch-color-white-of-over-red-primary-texts);
          ion-icon[slot=end] {
            margin-top: 4px;
          }
        }
      }
      ion-back-button {
        --transiton: none;
        --ripple-color: transparent;
        --color: var(--esch-color-pink-of-over-red-secondary-texts);
        --color-focused: var(--esch-color-pink-of-over-red-secondary-texts);
        --color-hover: var(--esch-color-pink-of-over-red-secondary-texts);
      }
      &.secondary-toolbar {
        --color: var(--esch-color-gray-of-any-thing-else);
        --background: var(--esch-color-gray-of-secondary-toolbars);
        ion-title {
          font-size: 15px;
          padding-inline-start: calc(var(--esch-padding-of-content-on-mobile) * 2);
        }
        ion-buttons {
          ion-button {
            --color: var(--esch-color-gray-of-any-thing-else);
            --color-focused: var(--esch-color-gray-of-any-thing-else);
            --color-hover: var(--esch-color-gray-of-any-thing-else);
          }
        }
      }
    }
    &.header-collapse-condense-inactive {
      display: none;
    }
  }
  ion-content {
    &.comments-content {
      .comment-self {
        margin: 0;
        padding: var(--esch-padding-of-content-on-mobile) calc(var(--esch-padding-of-content-on-mobile) * 2);
        .comment-author {
          font-size: 14px;
        }
      }
    }
    .list-title {
      margin: auto;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      height: var(--esch-padding-of-topbar);
      max-height: var(--esch-padding-of-topbar);
      padding: var(--esch-padding-of-content-on-mobile) var(--esch-padding-of-content-on-mobile) var(--esch-padding-of-content-on-mobile) calc(var(--esch-padding-of-content) / 3);
    }
    .project--section-title {
      border-top: 1px solid var(--esch-color-gray-of-tables-thead);
      padding: calc(var(--esch-padding-of-content-on-mobile) * 2);
      background: transparent;
      padding-bottom: 0;
      margin: 0;
    }
    .table-container {
      &:before {
        display: none;
      }
      table {
        margin: calc(var(--esch-padding-of-content-on-mobile) * 2);
        margin-top: 0;
        width: calc(100% - (var(--esch-padding-of-content-on-mobile) * 2));
      }
    }
    .resources-list {
      margin: 0;
      min-height: 100%;
      background: var(--esch-color-gray-of-parts);
      padding: calc(var(--esch-padding-of-content-on-mobile) * 2);
      .resource-tile--container {
        margin: 0 0 calc(var(--esch-padding-of-content-on-mobile) * 2) 0;
        width: 100%;
        --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
        --background-hover: var(--esch-color-blue-of-parts);
        --background-activated-opacity: 0;
        ion-button {
          &.resource-tile-add {
            border: 2px dotted var(--esch-color-gray-of-tables-borders);
          }
          &:not(.resource-tile-add) {
            background: white;
          }
          &.resource-tile--delete {
            background: transparent;
          }
          a {
            padding: 10px;
          }
          .resource-tile--title {
            overflow: visible;
          }
        }
      }
    }
    .card-grid--title {
      padding: 0 calc(var(--esch-padding-of-content-on-mobile) * 2);
    }
    .card-grid {
      flex-wrap: wrap;
      @media (max-width: 767px) {
        flex-wrap: nowrap;
        flex-direction: column;
      }
      padding: var(--esch-padding-of-content-on-mobile) calc(var(--esch-padding-of-content-on-mobile) * 2);
      .card-grid-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: calc((100% / 3) - 20px);
        .card-grid-item--title {
          font-size: 20px;
        }
        @media (max-width: 767px) {
          width: 100%;
          margin: 6px auto;
        }
      }
    }
    ion-list {
      --background: var(--esch-color-white-of-any-thing-else);
      ion-item-divider {
        --background: var(--esch-color-gray-of-parts);
      }
      ion-item {
        --padding-end: 0;
        --padding-bottom: 0;
        --detail-icon-opacity: 1;
        --inner-box-shadow: none;
        --ripple-color: transparent;
        --background-hover-opacity: 0;
        --background-focused-opacity: 0;
        --background-activated-opacity: 0;
        --highlight-color-focused: transparent;
        --ion-color-primary: var(--esch-color-gray-of-primary-texts);
        --inner-padding-bottom: calc(var(--esch-padding-of-content-on-mobile) / 2);
        --border-color: var(--esch-color-gray-of-tables-thead);
        --padding-top: var(--esch-padding-of-content-on-mobile);
        --padding-start: var(--esch-padding-of-content-on-mobile);
        --ion-safe-area-left: var(--esch-padding-of-content-on-mobile);
        --ion-safe-area-right: var(--esch-padding-of-content-on-mobile);
        --detail-icon-color: var(--esch-color-gray-of-tables-borders);

        ion-label[position="stacked"] {
          margin-bottom: calc(var(--esch-padding-of-content-on-mobile) * 2);
        }
        .data-value {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin: 0;
          width: 100%;
        }
        .data-value--add-button {
          width: 100%;
          background: transparent;
          border: 1px dashed var(--esch-color-gray-of-tables-thead);
          padding: 6px var(--esch-padding-of-inputs);
          max-height: var(--esch-height-of-harmonized-input-field);
          ion-button {
            font-weight: 400;
            text-transform: none;
            --padding-end: 0.5em;
            --padding-start: 0.5em;
            --color: var(--esch-color-gray-of-inputs-text);
            --background-focused: transparent !important;
            --background-hover: transparent !important;
            ion-icon {
              font-size: 18px;
              margin-right: 15px;
            }
          }
        }
        &.item-input,
        &.item-label-stacked,
        &.item-multiple-inputs,
        &.project--section-item {
          --inner-border-width: 0px 0px 0px 0px;
          --border-color: transparent;
          ion-select {
            padding: var(--esch-padding-of-inputs);
            color: var(--esch-color-gray-of-inputs-text);
            background: var(--esch-color-gray-of-inputs);
            --background: var(--esch-color-gray-of-inputs)
          }
        }
        .ion-color-danger {
          --ion-color-danger: var(--esch-color-red-of-parts);
        }
        .closer-ion-label {
          margin-top: 0;
          &.md {
            margin-top: 12px;
          }
        }
        &:last-child {
          margin-bottom: var(--esch-padding-of-content-on-mobile);
        }
        .DraftEditor-root {
          background: var(--esch-color-gray-of-inputs);
          border-radius: 2px;
        }
        .checkbox-list {
          width: 100%;
          ion-item {
            --padding-start: 0;
          }
        }
        ion-radio.in-item.interactive {
          margin-inline-end: 0;
          margin-top: 0;
          width: 20px;
          height: 30px;
        }
      }
      & + .list-title {
        border-top: 1px solid var(--esch-color-gray-of-tables-thead);
      }
    }
  }
  ion-footer {
    ion-toolbar {
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
    }
  }
  .bottom-controls--comments {
    min-height: auto !important;
  }
  .search-field--open {
    width: 180px;
  }
}

.main-content.not-desktop {
  --background: var(--esch-color-red-of-parts);
  --border: 0.55px solid var(--esch-color-red-of-parts);
  --color: var(--esch-color-pink-of-over-red-secondary-texts);
  --color-selected: var(--esch-color-white-of-over-red-primary-texts);
  ion-tab-button {
    height: auto;
    --padding-end: 0;
    --padding-start: 0;
    --ripple-color: transparent;
    
    ion-icon {
      display: none;
    }

    &::before {
      display: block;
      color: inherit;
      font-weight: 700;
      font-family: "Font Awesome 5 Pro";
      font-size: 19px;
      margin-bottom: 4px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 4px;
    }

    &[tab="projects"]::before {
      content: '\f1b3';
    }
    &[tab="sponsors"]::before {
      content: '\f10d';
    }
    &[tab="resources"]::before {
      content: '\f15c';
    }
    &[tab="account"]::before {
      content: '\f0c0';
    }
    &[tab="settings"]::before {
      content: '\f013';
    }

  }
}

.plt-mobile {
  /* Creation/Edition modals */
  ion-modal {
    --esch-padding-top-of-content-parts: calc(var(--esch-padding-of-content-on-mobile) * 2);
    ion-header {
      ion-toolbar {
        --padding-start: var(--esch-padding-of-content-on-mobile);
        --padding-end: var(--esch-padding-of-content-on-mobile);
        ion-title {
          padding-inline-start: calc(var(--esch-padding-of-content-on-mobile) * 2);
          padding-inline-end: 0;
          text-align: left;
        }
      }
    }
    ion-content {
      ion-list {
        ion-item {
          --border-color: transparent;
        }
      }
    }
  }
  /* Confirmation modals */
  .alert-button-group {
    flex-wrap: nowrap;
    margin-inline-end: 0;
    padding: var(--esch-padding-of-content-on-mobile);
    button {
      min-width: 40%;
      max-width: 40%;
    }
  }
  .picker-toolbar {
    flex-wrap: nowrap;
    margin-inline-end: 0;
    padding: var(--esch-padding-of-content-on-mobile);
    button {
      width: 100%;
      text-align: center;
    }
  }
  .picker-columns .picker-opts .picker-opt-selected{
    color: var(--esch-color-blue-of-any-thing-else);
  }
}

@media (min-width: 551px) and (max-width: 1100px) {

  .main-content ion-content.sponsors-grid .card-grid .card-grid-item {
    width: calc((100% / 2) - 20px);
    &:nth-child(3n+1) {
      margin-left: 10px;
    }
    &:nth-child(2n+1) {
      margin-left: 0;
    }
  }

}

@media (orientation: landscape) and (max-width: 767px) {

  :root {
    --esch-height-of-bottom-action-bars: 50px;
  }

  .picker-toolbar ion-button,
  .bottom-controls ion-button,
  .alert-button-group ion-button {
    min-height: calc(var(--esch-height-of-bottom-action-bars) - 15px);
    max-height: calc(var(--esch-height-of-bottom-action-bars) - 15px);
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (max-width: 767px) {

  .table-container {
    padding-right: calc(var(--esch-padding-of-content-on-mobile) * 2);
    table {
      border-right: none;
      border-bottom: none;
      thead tr:first-child {
        display: none;
      }
      tr {
        position: relative;
        &:nth-child(odd) {
          background: var(--esch-color-gray-of-parts);
        }
        td {
          display: block;
          border-bottom: none;
          text-align: left !important;
          &:not(:first-child) {
            padding-top: 0;
          }
          &:not(:last-child) {
            border-bottom: none;
          }
          &:last-child {
            border-bottom: none;
          }
          &::before {
            content: attr(data-column);
            font-weight: 700;
          }
        }
      }
    }
  }

  .sponsor-modal--visuals,
  .sponsor-modal > ion-img {
    height: 200px;
    max-height: 200px;
  }

  .sponsor-modal iframe {
    height: auto;
  }

}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  :root {
    --esch-padding-of-forms-containers: 25px;
    --esch-padding-of-forms-submit-containers: 20px;
  }

  .auth-content {
    border-bottom-width: 14px;
  }

  .auth-content::after {
    border-width: 14px;
  }

  .auth-hat {
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    .auth-hat--link {
      position: absolute;
      border: none;
      padding: 0;
      top: 40px;
      left: 40px;
    }
  }

  .esch-auth-title {
    font-size: 22px;
  }

  .esch-auth-subtitle {
    font-size: 15px;
  }

  .esch.auth-form-wrap {
    margin: auto;
  }

  .auth-hat--lang-switch {
    margin-bottom: 1.6em;
  }

  .auth-form-wrap {
    width: 93%;
    margin: 32px 0 0 0 !important;
    &:nth-child(2) {
      margin-top: 2px !important;
    }
  }

  .esch.auth-form-content-switch {
    flex-direction: column;
  }

  .auth-message {
    padding: 40px 18px 40px
  }

  .auth-message--title {
    font-size: 16px;
  }

  .auth-message--description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
  }

  .grid-container {
    margin: 0;
  }

}
