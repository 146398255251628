/****************** FONTS ********************/

// @font-face {
//   font-family: 'Font Awesome 5 Brands';
//   font-style: normal;
//   font-weight: normal;
//   font-display: auto;
//   src: url("/assets/webfonts/fa-brands-400.eot");
//   src: url("/assets/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
//        url("/assets/webfonts/fa-brands-400.woff2") format("woff2"),
//        url("/assets/webfonts/fa-brands-400.woff") format("woff"),
//        url("/assets/webfonts/fa-brands-400.ttf") format("truetype"),
//        url("/assets/webfonts/fa-brands-400.svg#fontawesome") format("svg");
// }

// @font-face {
//   font-family: 'Font Awesome 5 Duotone';
//   font-style: normal;
//   font-weight: 900;
//   font-display: auto;
//   src: url("/assets/webfonts/fa-duotone-900.eot");
//   src: url("/assets/webfonts/fa-duotone-900.eot?#iefix") format("embedded-opentype"),
//        url("/assets/webfonts/fa-duotone-900.woff2") format("woff2"),
//        url("/assets/webfonts/fa-duotone-900.woff") format("woff"),
//        url("/assets/webfonts/fa-duotone-900.ttf") format("truetype"),
//        url("/assets/webfonts/fa-duotone-900.svg#fontawesome") format("svg");
// }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("/assets/webfonts/fa-light-300.eot");
  src: url("/assets/webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
       url("/assets/webfonts/fa-light-300.woff2") format("woff2"),
       url("/assets/webfonts/fa-light-300.woff") format("woff"),
       url("/assets/webfonts/fa-light-300.ttf") format("truetype"),
       url("/assets/webfonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("/assets/webfonts/fa-regular-400.eot");
  src: url("/assets/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
       url("/assets/webfonts/fa-regular-400.woff2") format("woff2"),
       url("/assets/webfonts/fa-regular-400.woff") format("woff"),
       url("/assets/webfonts/fa-regular-400.ttf") format("truetype"),
       url("/assets/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("/assets/webfonts/fa-solid-900.eot");
  src: url("/assets/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
       url("/assets/webfonts/fa-solid-900.woff2") format("woff2"),
       url("/assets/webfonts/fa-solid-900.woff") format("woff"),
       url("/assets/webfonts/fa-solid-900.ttf") format("truetype"),
       url("/assets/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}

/****************** HTML & BODY REWRITES ********************/

html.md,
html.ios {
  --ion-default-font: var(--ion-font-family);
}

body {
  font-family: var(--ion-font-family);
  -webkit-font-smoothing: antialiased;
}

.center-element {
  flex: 1 0 auto;
  flex-direction: column;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  white-space: normal;
}

.hide-tabs {
  display: none;
}

/* Ion Toolbar */

ion-toolbar ion-button span {
  display: none;
}
ion-split-pane ion-toolbar ion-button span {
  display: inline;
}

.list-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--ion-font-family);
  font-size: 18px;
  font-weight: 600;
  margin: 20px 12px 10px;
}

ion-split-pane .list-title {
  max-width: var(--esch-maxwidth-of-parts);
  margin: 0 auto 14px;

  &--wide {
    max-width: none;
    margin-right: 0;
  }
}

/* Projects */

.projects--item-lock {
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--esch-color-gray-of-primary-texts);
  &.lockOpen{
    color: var(--esch-color-gray-of-secondary-texts);
  }
}

.project--section-title {
  font-family: var(--ion-font-family);
  font-size: 18px;
  font-weight: 600;
  margin: 20px 12px 10px;
}

.project-view-first-block {
  ion-item:last-child {
    margin-top: 0;
  }
}

.main-content .modal-form--apart{
  ion-item-divider {
    font-weight: 500;
    font-size: var(--esch-fontsize-of-any-thing-else) !important;
    padding-bottom: calc(var(--esch-padding-bottom-of-content-parts) / 2);
    border-bottom: 1px solid var(--esch-color-gray-of-parts-borders);
    border-top: 1px solid var(--esch-color-gray-of-parts-borders);
    --color: var(--esch-color-gray-of-any-thing-else)
  }
  ion-item.ion-activatable {
    --border-color: transparent;
    ion-label:nth-child(2) {
      padding-top: calc(var(--esch-padding-top-of-content-parts) / 4);
      padding-bottom: calc(var(--esch-padding-top-of-content-parts) / 4);
    }
    &.project--section-item {
      ion-label {
        padding-left: var(--esch-distance-between-a-checkbox-and-its-label);
      }
    }
  }
}

/* MISC Items */

.item-label ion-label[position="stacked"] {
  white-space: normal;

  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
}
.item-label.md ion-label[position="stacked"] {
  max-width: 133%;
  width: 133%;

  font-size: 20px;
}

.change-data-button {
  text-align: right;
}

.nav-link {
  text-decoration: none;
  padding: 10px;
  text-transform: uppercase;
  color: grey;
}
.nav-link--active {
  font-weight: bold;
  border-bottom: 5px solid black;
}

ion-item.item-has-focus {
  --highlight-color-focused: var(--ion-color-dark, #000);

  .label-stacked {
    color: var(--ion-color-dark, #000);
  }
}

.send-comment-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  width: 100%;
  padding: 12px 0;

  .send-button {
    position: relative;
    z-index: 1;

    bottom: 0;
    right: 0;
    font-size: 8px;
    width: 46px;
    min-height: 46px;
    margin: 0 0 0 14px;
    --border-radius: 50%;
    --box-shadow: none;
    --ion-color-primary: var(--esch-color-blue-of-any-thing-else);

    --padding-end: 0;
    --padding-start: 0;
  }
}

/****************** IONIC REWRITES ********************/

/* Dividers */

ion-item-divider {
  padding: calc(var(--esch-padding-top-of-content-parts) / 2) var(--esch-padding-top-of-content-parts);
}

/* Main Inputs Rewrite */

ion-split-pane ion-input input.native-input.native-input,
ion-split-pane ion-select,
ion-split-pane ion-datetime,
ion-split-pane ion-textarea,
ion-split-pane .data-value--input {
  padding: var(--esch-padding-of-inputs);
  color: var(--esch-color-gray-of-inputs-text);
  background: var(--esch-color-gray-of-inputs);
  --background: var(--esch-color-gray-of-inputs);
  &:focus {
    background: rgba(var(--ion-color-dark-rgb), 0.1);
  }
}

/* Ion Selects */

ion-select,
.main-content ion-select {
  background: var(--esch-color-gray-of-inputs);
  min-height: var(--esch-height-of-harmonized-input-field);
  max-height: var(--esch-height-of-harmonized-input-field);
  --padding-top: var(--esch-padding-of-inputs);
  --padding-bottom: var(--esch-padding-of-inputs);
  --padding-end: var(--esch-padding-of-inputs);
  --padding-start: var(--esch-padding-of-inputs);
  &.md[interface="popover"],
  &.ios[interface="popover"] {
    padding: 10px;
    background: var(--esch-color-gray-of-selects);
    font-size: var(--esch-fontsize-of-any-thing-else);
    --color: var(--esch-color-gray-of-selects-text);
  }
}

/* Ion Datetime */

.main-content ion-datetime {
  min-height: var(--esch-height-of-harmonized-input-field);
  max-height: var(--esch-height-of-harmonized-input-field);
  line-height: calc(var(--esch-height-of-harmonized-input-field) / 2);
  padding: 10px;
  background: var(--esch-color-gray-of-selects);
  font-size: var(--esch-fontsize-of-any-thing-else);
  --color: var(--esch-color-gray-of-selects-text);
}

/* Inputs text */

ion-input {
  min-height: 60px;
  background: var(--esch-color-gray-of-inputs);
  font-size: var(--esch-fontsize-of-any-thing-else);
  --min-height: 60px;
  --color: var(--esch-color-gray-of-inputs-text);
  --background: var(--esch-color-gray-of-inputs);
  &.md,
  &.ios {
    --padding-bottom: 0;
    --padding-top: 0;
    input.native-input.native-input {
      --padding-top: var(--esch-padding-of-inputs);
      --padding-bottom: var(--esch-padding-of-inputs);
      --padding-end: var(--esch-padding-of-inputs);
      --padding-start: var(--esch-padding-of-inputs);
      background: var(--esch-color-gray-of-inputs);
      height: 100%;
      &[readonly] {
        color: var(--esch-color-gray-of-inputs-disabled-text);
        background: var(--esch-color-gray-of-inputs-disabled);
        --background: var(--esch-color-gray-of-inputs-disabled);
        cursor: not-allowed;
        user-select: none;
        user-focus: none;
      }
    }
  }
}

/* Inputs text as values */

.data-value {
  min-height: var(--esch-height-of-harmonized-input-field);
  padding-top: var(--esch-padding-of-inputs);
  padding-left: var(--esch-padding-of-inputs);
  padding-right: var(--esch-padding-of-inputs);
  padding-bottom: var(--esch-padding-of-inputs);
  font-size: var(--esch-fontsize-of-any-thing-else);
  margin: 0 var(--esch-padding-top-of-content-parts);
  width: calc(100% - (var(--esch-padding-top-of-content-parts) * 2));
  --color: var(--esch-color-gray-of-inputs-text);
  --min-height: var(--esch-height-of-harmonized-input-field);
  &.data-value--file {
    background: transparent !important;
  }

  &--clear-button {
    position: absolute;
    z-index: 2;
    bottom: 12px;
    right: 40px;
  }
}

/* Inputs radio */

ion-radio {
  --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
  &.in-item.interactive {
    margin-inline-end: 26px;
    width: 20px;
    height: 30px;
  }
}

ion-radio-group {
  ion-item {
    --background-hover: var(--esch-color-blue-of-parts);
    --background-activated-opacity: 1;
    --ripple-color: transparent;
  }
}

/* Inputs checkbox */

ion-checkbox,
.alert-checkbox {
  --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
  &[slot="start"]{
    margin-inline-end: 10px;
  }
}

/* Inputs Textarea */

ion-textarea.send-input {
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
  background: var(--esch-color-white-of-parts);
  border-radius: 2px;
  transition: width 0.2s ease-in;
  --padding-top: var(--esch-padding-of-inputs);
  --padding-end: var(--esch-padding-of-inputs);
  --color: var(--esch-color-gray-of-inputs-text);
  --padding-start: var(--esch-padding-of-inputs);
  --padding-bottom: var(--esch-padding-of-inputs);
  textarea,
  .textarea-wrapper {
    max-height: 300px !important;
  }
}

ion-textarea.send-input--with-value {
  width: calc(100% - 40px);
}

/* Buttons */

ion-button {
  margin: auto;
  font-size: var(--esch-fontsize-of-any-thing-else);
  letter-spacing: var(--esch-buttons-letter-spacing);

  &.blue-style,
  &[type="submit"] {
    height: var(--esch-buttons-height);
    min-height: var(--esch-buttons-height);
    font-weight: var(--esch-fontweight-of-buttons);
    --border-radius: 0;
    --background: var(--esch-color-blue-of-any-thing-else);
    --color: var(--esch-color-white-of-over-red-primary-texts);
    --background-hover: var(--esch-color-button-hover);
    --box-shadow: var(--esch-color-blue-button-shadow);
    --background-focused: var(--esch-color-blue-of-any-thing-else);
    --background-activated: var(--esch-color-blue-of-any-thing-else);
    --ion-toolbar-background: var(--esch-color-white-of-over-red-primary-texts);
    --padding-start: var(--esch-padding-of-forms-submit-buttons);
    --padding-end: var(--esch-padding-of-forms-submit-buttons);
    &.shorter {
      --padding-start: calc(var(--esch-padding-of-forms-submit-buttons) / 2);
      --padding-end: calc(var(--esch-padding-of-forms-submit-buttons) / 2);
    }
  }

  &.danger-style {
    height: var(--esch-buttons-height);
    min-height: var(--esch-buttons-height);
    font-weight: var(--esch-fontweight-of-buttons);
    --border-radius: 0;
    --background: var(--esch-color-red-of-any-thing-else);
    --color: var(--esch-color-white-of-over-red-primary-texts);
    --background-hover: var(--esch-color-button-hover);
    --box-shadow: var(--esch-color-red-button-shadow);
    --background-focused: var(--esch-color-red-of-any-thing-else);
    --background-activated: var(--esch-color-red-of-any-thing-else);
    --ion-toolbar-background: var(--esch-color-white-of-over-red-primary-texts);
    --padding-start: var(--esch-padding-of-forms-submit-buttons);
    --padding-end: var(--esch-padding-of-forms-submit-buttons);
  }

  &[disabled] {
    --color: var(--esch-color-of-disabled-buttons-text);
    --box-shadow: var(--esch-value-of-disabled-buttons-boxshadow);
    --background: var(--esch-color-of-disabled-buttons-background);
    --ion-toolbar-background: var(--esch-color-of-disabled-buttons-text);
  }
  &.reversed-colors {
    letter-spacing: var(--esch-buttons-letter-spacing);
    --padding-end: 20px;
    --padding-start: 20px;
  }
  &.min-widthed {
    --padding-end: 8px;
    --padding-start: 8px;
    min-width: var(--esch-buttons-width);
  }
  &.over-red-ion-button {
    --box-shadow: none;
    font-weight: 400;

    &.button-clear {
      outline: none;
      text-transform: none;
      letter-spacing: var(--esch-buttons-letter-spacing);
      --padding-top: 0;
      --padding-start: 20px;
      --padding-end: 20px;
      --padding-bottom: 0;
      --ion-color-base: var(--esch-color-white-of-over-red-primary-texts) !important;
      --ion-color-dark: var(--esch-color-white-of-over-red-primary-texts) !important;
    }
  }
}

/* select popup */
ion-alert.select-alert {
  --width: 400px;
  --max-width: 90%;
  .alert-checkbox-group {
    max-height: 90%;
  }
}

.picker-opts,
.picker-toolbar,
.alert-radio-group,
.alert-button-group {
  --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
}

.alert-button-group .alert-button,
.picker-toolbar .picker-toolbar-button {
  height: var(--esch-buttons-height);
  min-height: var(--esch-buttons-height);
  max-height: var(--esch-buttons-height);
  font-weight: var(--esch-fontweight-of-buttons);
  background: var(--esch-color-blue-of-any-thing-else);
  color: var(--esch-color-white-of-over-red-primary-texts);
  box-shadow: var(--esch-color-blue-shadow-of-any-thing-else);
  padding-left: var(--esch-padding-of-forms-submit-buttons);
  padding-right: var(--esch-padding-of-forms-submit-buttons);
  --ion-color-primary: var(--esch-color-white-of-over-red-primary-texts);
  span.alert-button-inner {
    justify-content: center;
  }
  &:first-child:not(:last-child) {
    margin-right: var(--esch-margin-between-two-forms-submit-buttons);
    background: transparent;
    color: var(--esch-color-gray-of-inputs-text);
    box-shadow: inset 0 0 0 2px var(--esch-color-gray-of-tables-borders);
    --ion-color-primary: var(--esch-color-gray-of-inputs-text);
    --background: transparent;
    --box-shadow: none;
    &.picker-toolbar-cancel {
      margin: auto;
    }
  }
  &:hover {
    background: var(--esch-color-blue-button-hover);
  }
  &.danger {
    background: var(--esch-color-red-of-any-thing-else);
    &:hover {
      background: var(--esch-color-red-button-hover);
    }
  }
}

/* Ion Items */

.main-content ion-item {
  font-size: var(--esch-fontsize-of-any-thing-else);
  font-weight: var(--esch-fontweight-of-any-thing-else);
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: var(--esch-color-white-of-parts, #fff);
  --color: var(--esch-color-gray-of-any-thing-else);
  --border-color: var(--esch-color-gray-of-parts-borders);

  ion-label {
    width: 100%;
    margin: auto;
    padding-top: var(--esch-padding-top-of-content-parts);
    padding-left: var(--esch-padding-top-of-content-parts);
    padding-right: var(--esch-padding-top-of-content-parts);
    padding-bottom: var(--esch-padding-top-of-content-parts);
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --color: var(--esch-color-gray-of-any-thing-else) !important;

    &[position="stacked"] {
      transform: none;
      width: 100% !important;
      max-width: 100% !important;
      font-size: var(--esch-fontsize-of-any-thing-else) !important;
      padding-bottom: calc(var(--esch-padding-bottom-of-content-parts) / 2);
      --ion-color-primary: var(--esch-color-gray-of-any-thing-else);
    }

    &.closer-ion-label {
      padding-left: var(--esch-distance-between-a-checkbox-and-its-label);
    }

    &.danger {
      color: var(--esch-color-red-of-any-thing-else);
    }
  }

  ion-reorder,
  p[slot="end"] {
    margin-inline-start: 0;
    margin-inline-end: var(--esch-padding-top-of-content-parts);
    text-align: end;

    span {
      font-size: 14px;
      line-height: 22px;
      color: var(--ion-color-step-600, #666666);
    }
  }

  &.item-label {
    ion-input {
      padding-left: var(--esch-padding-top-of-content-parts);
      padding-right: var(--esch-padding-top-of-content-parts);
      min-height: var(--esch-height-of-harmonized-input-field);
      max-height: var(--esch-height-of-harmonized-input-field);
      --min-height: var(--esch-height-of-harmonized-input-field);
      input {
        margin: 0 var(--esch-padding-top-of-content-parts);
      }
    }
    ion-checkbox {
      margin-top: var(--esch-padding-top-of-content-parts);
      margin-left: var(--esch-padding-top-of-content-parts);
      margin-bottom: var(--esch-padding-top-of-content-parts);
      & + ion-label {
        padding-left: calc(var(--esch-padding-bottom-of-content-parts) / 2);
      }
    }
    ion-select,
    ion-datetime,
    .data-value--file,
    .Draftail-Editor {
      margin: auto;
      width: calc(100% - (var(--esch-padding-top-of-content-parts) * 2));
      ion-button {
        margin: 0;
      }
    }
    .data-value--file {
      &.data-value--add-button {
        background: transparent;
        border: 1px dashed var(--esch-color-gray-of-tables-thead);
        padding: 6px var(--esch-padding-of-inputs);
        max-height: var(--esch-height-of-harmonized-input-field);

        ion-button {
          font-weight: 400;
          text-transform: none;
          --padding-end: 0.5em;
          --padding-start: 0.5em;
          --color: var(--esch-color-gray-of-inputs-text);
          --background-focused: transparent !important;
          --background-hover: transparent !important;
          ion-icon {
            font-size: 18px;
            margin-right: 15px;
          }
        }
      }
      &:not(.data-value--add-button) {
        display: flex;
        align-items: center;
      }
      a {
        display: flex;
        font-weight: 600;
        user-focus: none;
        user-select: none;
        align-items: center;
        text-decoration: none;
        margin-right: 18px;
        --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
        ion-icon {
          margin-right: 6px;
        }
      }
      .data-value--file-size {
        font-size: 13px;
      }
      &.data-value.data-value--input {
        font-weight: 600;
        user-focus: none;
        user-select: none;
        color: var(--esch-color-blue-of-any-thing-else);
        ion-icon {
          margin-right: 6px;
        }
        .data-value--file-size {
          margin-left: 10px;
          ion-icon {
            font-size: 20px;
          }
        }
      }
    }
  }

  &:last-child {
    --border-color: transparent;
  }

  &.item-label-stacked:last-child {
    margin-bottom: var(--esch-padding-top-of-content-parts);
  }

  &.project--section-item {
    .checkbox-list {
      width: 100%;
    }
  }
}

.data-value.data-value--file {
  justify-content: space-between;
  margin-bottom: 4px !important;
  padding: calc(var(--esch-padding-of-inputs) - 8px) var(--esch-padding-of-inputs);
  line-height: 1;
  background: var(--esch-color-gray-of-inputs);

  a {
    display: flex;
    align-items: center;
    --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
  }

  .data-value--file-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .data-value--file-icon {
    font-size: 20px;
    margin-right: 6px;
  }

  .data-value--file-size ion-button {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 4px;
    --padding-end: 4px;

    font-size: 12px;
  }
}

/****************** VARIOUS REWRITES ********************/

/* Comments */

.comments-content {
  background: var(--esch-color-gray-of-parts);
  &::part(scroll) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.comments-list {
  flex: 0 0 auto;
  width:100%;
  max-width: 780px;
  margin: 0px auto;
}

.comment {
  margin: 12px 10px;
  padding-right: 18px;
  color: var(--esch-color-gray-of-any-thing-else);
  &-author {
    color: inherit;
    font: 500 16px/18px var(--ion-font-family);
  }
  &-date {
    color: inherit;
    margin-left: 12px;
    font-weight: 300;
  }
  &-text {
    color: inherit;
    padding: 18px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    background: var(--esch-color-white-of-any-thing-else);
    &--pre-wrap {
      white-space: pre-wrap;
    }
  }
  &-self {
    padding-left: 18px;
    padding-right: 0;
    .comment-author {
      text-align: left;
    }
    .comment-text {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

ion-split-pane .comment {
  margin: 30px 10px;
  padding-right: 40px;
  &-self {
    padding-left: 40px;
    padding-right: 0;
  }
  &-text {
    padding: 32px;
  }
}

/* Bottom bars & Footers */

.picker-toolbar,
.bottom-controls,
.alert-button-group {
  bottom: 0;
  left: 0px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center !important;
  background: var(--esch-color-blue-of-parts) !important;
  padding: var(--esch-padding-of-forms-submit-containers) 0 !important;
  min-height: var(--esch-maxheight-of-forms-submit-containers);
  max-height: var(--esch-maxheight-of-forms-submit-containers);
  border-top: 1px solid var(--esch-color-blue-of-parts-borders);
  div {
    position: relative;
    max-width: 760px;
    margin: auto;
  }
  &--right div {
    text-align: right;
  }
  &--pages {
    display: inline-block;
    margin: 0 12px;
    vertical-align: -6px;
    font-size: 14px;
    font-weight: 500;
  }
  &.picker-toolbar {
    border-top: none;
    border-bottom: 1px solid var(--esch-color-blue-of-parts-borders);
    .picker-button {
      height: 100%;
    }
  }
  ion-button {
    min-height: var(--esch-buttons-height);
    max-height: var(--esch-buttons-height);
    margin: 0 5px;
    &:first-child:not(:last-child) {
      margin-right: var(--esch-margin-between-two-forms-submit-buttons);
    }
  }

  &--appart {
    justify-content: space-evenly !important;
  }
  &--comments {
    height: auto !important;
    min-height: var(--esch-height-of-bottom-action-bars) !important;
    padding: 0 12px !important;
    max-height: none;
  }
}

.main-content .bottom-controls--comments {
  padding: 0 var(--esch-padding-of-content) !important;
}

ion-footer ion-toolbar {
  --background: transparent;
  --min-height: var(--esch-height-of-bottom-action-bars);
}

/* Modals */

.modals {
  ion-header{
    border-bottom: 1px solid var(--esch-color-gray-of-parts-borders);
    --box-shadow: none;
    &::after{
      display: none;
    }
    ion-toolbar {
      --padding-top: var(--esch-padding-of-modals);
      --padding-start: var(--esch-padding-of-modals);
      --padding-end: var(--esch-padding-of-modals);
      --padding-bottom: var(--esch-padding-of-modals);
      ion-button {
        --ionicon-stroke-width: 2.2em;
        &.button-clear {
          --ion-color-primary: var(--esch-color-blue-of-any-thing-else);
        }
      }
      ion-title {
        font-weight: 600;
      }
    }
  }
  ion-content {
    ion-list {
      display: flex;
      flex-direction: column;
      flex: auto;
      height: 100%;
      padding-top: 0;
      background: var(--esch-color-white-of-any-thing-else);
      &.no-bottom-controls{
        display: block;
        overflow: auto;
        padding-bottom: 0;
      }
      ion-item {
        --border-width: 0;
        --highlight-background: transparent;
        .input-clear-icon {
          position: absolute;
          right: 10px;
        }
      }
      .bottom-controls {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

.alert-wrapper,
.modal-wrapper,
.picker-wrapper,
.popover-viewport {
  border-radius: 0 !important;
  --background: var(--esch-color-white-of-any-thing-else);
  --ion-item-color: var(--esch-color-gray-of-primary-texts);
  --ion-border-color: var(--esch-color-gray-of-tables-thead);
  --ion-background-color: var(--esch-color-white-of-any-thing-else);
  .alert-head {
    padding: var(--esch-padding-of-modals);
    h2 {
      font-weight: 600;
      padding: 0 20px;
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  ion-header {
    border-color: var(--esch-color-gray-of-tables-thead);
    ion-toolbar {
      --color: var(--esch-color-gray-of-primary-texts);
      --background: var(--esch-color-white-of-any-thing-else);
    }
  }
  ion-list {
    padding-top: 0;
    padding-bottom: 0;
    ion-item {
      --background: var(--esch-color-white-of-any-thing-else);
      &:last-child {
        --border-color: transparent;
      }
    }
  }
}

.picker-wrapper {
  --height: 320px;
}

.confirmation-alert-modal {
  .alert-wrapper {
    --max-width: 360px;
    .alert-head + .alert-message {
      padding: 35px;
      border-top: 1px solid var(--ion-color-step-150, #d9d9d9);
    }
  }
}

/* Toasters */

.bottom-toasts {
  font-size: 15px;
  --start: 24px;
  --end: initial;
  --border-radius: 0;
  --ion-safe-area-bottom: 16px;
  --width: var(--esch-width-of-the-navigation-left-panel);
  --max-width: var(--esch-width-of-the-navigation-left-panel);
  &.ion-color-success {
    --ion-color-success: var(--esch-color-white-of-any-thing-else);
    --ion-color-success-contrast: var(--esch-color-gray-of-primary-texts);
  }
  &.ion-color-danger {
    --ion-color-success: var(--esch-color-white-of-any-thing-else);
    --ion-color-success-contrast: var(--esch-color-red-button-hover);
  }
}
