.revision-data-container {
    display: flex;
    justify-content: center;
    width: 100%;
}
.revision-data-added {
    background-color: #dfd;
    width: 100%;
    padding: 10px 0;
}
.revision-data-deleted {
    background-color: #fdd;
    width: 100%;
    padding: 10px 0;
}
// for revision list page
.revisions-title{
    text-align: center;
}