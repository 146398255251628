.search-field {
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 41px;
  height: 41px;
  overflow: hidden;
  transition: width 0.15s ease-in;

  background: var(--esch-color-gray-of-parts-borders, #eee);
  border-radius: 20px;

  align-self: center;
  vertical-align: middle;

  &--open {
    width: 260px;
  }

  &--input {
    order: 2;
    flex: 1 1;
    width: 0;
    min-height: 0;
    font-weight: 400;
    text-transform: none;
    --padding-top: 0px;
    --padding-bottom: 0px;

    input.native-input.native-input {
      margin: 0;
      --background: transparent;
      background: transparent !important;
      padding-left: 16px;

      &:focus {
        --background: transparent;
        background: transparent;
      }
    }
  }

  &--filter {
    position: absolute;
    z-index: 4;
    height: 32px;
    right: 36px;

    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 0;
    --padding-bottom: 0;
    font-size: 9px;
  }

  &--button {
    order: 1;
    z-index: 2;
    width: 41px;
    min-width: 41px;
    height: 41px;
    margin: 0;
    --padding-start: 0;
    --padding-end: 0;
    ion-icon {
      font-size: 18px;
    }
  }
}

.search-field + ion-button {
  ion-icon {
    font-size: 18px;
  }
}
