.auth-hat {
  position: relative;
  z-index: 3;
  width: 100%;
  margin: 0;
  padding: 107px 119px 0 119px;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;

  @media (max-width: 550px) {
    flex-direction: column;
  }

  &--link {
    display: inline-block;
    vertical-align: -6px;
    margin: 0 1.1em 0 0;
    padding: 14px 20px;
    font: 700 14px var(--ion-font-family);
    word-spacing: 4px;
    color: var(--esch-color-white-of-over-red-primary-texts);
    text-decoration: none;
    border: 1px solid;
    border-radius: 4px;
    text-transform: uppercase;
    border-color: var(--esch-color-white-of-parts-borders);

    &::before {
      content: '←';
      margin: 0 6px 0 0;
    }
  }

  &--logo {
    width: var(--esch-width-of-the-logo-if-big);
  }

  &--lang-switch {
    --color: var(--esch-color-white-of-parts, #fff);
    font-weight: 700;
    --padding-start: 0.6em;
    --padding-end: 0.6em;
    opacity: 0.6;

    &-selected,
    &-selected[disabled] {
      --background: transparent;
      --color: var(--esch-color-white-of-parts, #fff);
      opacity: 1;
    }
  }
}

