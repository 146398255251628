.actions-button {
  width: 41px;
  height: 41px;
  margin: 0 0 0 6px;
  --padding-start: 0;
  --padding-end: 0;
  background: var(--esch-color-gray-of-parts-borders, #eee);
  border-radius: 20px;

  align-self: center;
  vertical-align: middle;
}
.print-icon{
  width: 22px;
  height: 22px;
}